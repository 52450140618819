import { Box, HStack } from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import { NavLink } from "react-router-dom";

import { SourceListTab_root$key } from "~/src/__relay_artifacts__/SourceListTab_root.graphql";
import { SolidButton } from "~/src/components/common/Button";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";

import {
  SourceTableDataRow,
  Props as SourceTableDataRowProps,
} from "./SourceTableDataRow";
import { StyledDescriptionHeaderColumn } from "./styles";

export type Props = {
  siteSlug: string;
  rootRef: SourceListTab_root$key;
  onSourceDeleteClick: SourceTableDataRowProps["onDeleteClick"];
};

const rootFragment = graphql`
  fragment SourceListTab_root on Query
  @refetchable(queryName: "SourceListTab_Query")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
  ) {
    sources(first: $count, after: $cursor)
      @connection(key: "SourceListTab_sources") {
      totalCount
      edges {
        node {
          slug
          ...SourceTableDataRow_source
        }
      }
    }
  }
`;

export const SourceListTab: FC<Props> = ({
  siteSlug,
  rootRef,
  onSourceDeleteClick,
}) => {
  const [searchText, setSearchText] = useState("");
  const {
    data: root,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(rootFragment, rootRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: root.sources.totalCount,
    hasNext,
    loadNext,
    refetch,
  });

  const sources = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const edges = root.sources?.edges || [];
    return edges.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [root.sources?.edges, tablePaginationProps.from, tablePaginationProps.to]);

  return (
    <Box my={"16px"}>
      <HStack justifyContent="flex-end" mb="16px" spacing="16px">
        <NavLink to={`/sites/${siteSlug}/sources/new`}>
          <SolidButton>登録</SolidButton>
        </NavLink>
      </HStack>
      <TableSearchField searchText={searchText} onChange={setSearchText} />
      <Table>
        <TableHeaderRow>
          <TableHeaderColumn>流入元</TableHeaderColumn>
          <TableHeaderColumn>タグ</TableHeaderColumn>
          <StyledDescriptionHeaderColumn>説明</StyledDescriptionHeaderColumn>
          <TableHeaderColumn>アクション</TableHeaderColumn>
        </TableHeaderRow>
        {sources.map((source) => (
          <SourceTableDataRow
            key={source.slug}
            siteSlug={siteSlug}
            sourceRef={source}
            onDeleteClick={onSourceDeleteClick}
          />
        ))}
      </Table>
      <TablePagination {...tablePaginationProps} />
    </Box>
  );
};

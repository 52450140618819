import {
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { FC, useMemo } from "react";
import { graphql, useFragment } from "react-relay/hooks";

import { SourceTableDataRow_source$key } from "~/src/__relay_artifacts__/SourceTableDataRow_source.graphql";
import {
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { TableActions } from "~/src/components/common/tables/TableActions";

import { StyledDescriptionDataColumn } from "./styles";

export type Props = {
  sourceRef: SourceTableDataRow_source$key;
  siteSlug: string;
  onDeleteClick: (sourceSlug: string) => void;
};

const sourceFragment = graphql`
  fragment SourceTableDataRow_source on Source {
    id
    name
    description
    tag
    slug
  }
`;

export const SourceTableDataRow: FC<Props> = ({
  sourceRef,
  siteSlug,
  onDeleteClick,
}) => {
  const source = useFragment(sourceFragment, sourceRef);

  const actionMenus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        to: `/sites/${siteSlug}/sources/${source.slug}/edit`,
      },
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => onDeleteClick(source.slug),
      },
    ],
    [onDeleteClick, siteSlug, source.slug]
  );

  return (
    <TableDataRow>
      <TableDataColumn type="text" data={source.name} />
      <TableDataColumn type="text" data={source.tag} />
      <StyledDescriptionDataColumn type="text" data={source.description} />
      <TableDataColumn
        type="actions"
        data={<TableActions actionMenus={actionMenus} />}
      />
    </TableDataRow>
  );
};

/**
 * @generated SignedSource<<38b7329dc3414eb3bc0517444caab771>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SourceListTabContainer_Query$variables = {};
export type SourceListTabContainer_QueryVariables = SourceListTabContainer_Query$variables;
export type SourceListTabContainer_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SourceListTab_root">;
};
export type SourceListTabContainer_QueryResponse = SourceListTabContainer_Query$data;
export type SourceListTabContainer_Query = {
  variables: SourceListTabContainer_QueryVariables;
  response: SourceListTabContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 120
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SourceListTabContainer_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SourceListTab_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SourceListTabContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "SourceConnection",
        "kind": "LinkedField",
        "name": "sources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "sources(first:120)"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "SourceListTab_sources",
        "kind": "LinkedHandle",
        "name": "sources"
      }
    ]
  },
  "params": {
    "cacheID": "c99955ab32b30b93c6dd3141e8adab07",
    "id": null,
    "metadata": {},
    "name": "SourceListTabContainer_Query",
    "operationKind": "query",
    "text": "query SourceListTabContainer_Query {\n  ...SourceListTab_root\n}\n\nfragment SourceListTab_root on Query {\n  sources(first: 120) {\n    totalCount\n    edges {\n      node {\n        slug\n        ...SourceTableDataRow_source\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SourceTableDataRow_source on Source {\n  id\n  name\n  description\n  tag\n  slug\n}\n"
  }
};
})();

(node as any).hash = "d7ec646645dc0eda7f44f714c2876184";

export default node;
